<template>
  <div class="cat">
    <!-- <filterBar /> -->
    <searchPage />
  </div>
</template>

<script>
// import filterBar from "../../../components/stall/category/filterBar.vue";
import searchPage from "../../../components/stall/category/searchPage.vue";
export default {
  name: "searchCategory",
  components: {
    // filterBar,
    searchPage,
  },
};
</script>

<style lang="scss" scoped>
.cat {
  background-color: #f7f9fd;
}
</style>
