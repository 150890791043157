<template>
  <div id="pageS">
    <div class="top">
      <filterBar />
    </div>
    <div class="main">
      <div class="Cards" v-for="product in products" :key="product.id">
        <router-link to="/product/:id">
          <product :product="product" />
        </router-link>
      </div>
      <!-- <product :product="product" /> -->
    </div>
  </div>
</template>

<script>
// import product from "./product.vue";
import product from "../home/product.vue";
import filterBar from "../category/filterBar.vue";
export default {
  name: "searchPage",
  props: ["product"],
  components: {
    product,
    filterBar,
  },
  data() {
    return {
      items: [{}],
      products: [
        {
          name: "suriya",
          price: 123,
          id: 1,
          quantity: 0,
        },
        {
          name: "hari",
          price: 1236,
          id: 2,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
#pageS {
  display: flex;
}
.main {
  display: flex;
  flex-wrap: wrap;
  // width: 80%;
}
.top {
  // width: 30%;
}
.Cards {
}
@media (max-width: 780px) {
  .top {
    display: none;
  }
}
</style>
