<template>
  <div class="search_filter">
    <!-- <div>
      <ul>
        <p>Brands</p>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Maccs</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Baars</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Bukks</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Luasis</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Nike</label>
        </li>
      </ul>
    </div> -->
    <div>
      <ul>
        <v-card flat color="transparent">
          <v-subheader> Price Range</v-subheader>
          <v-card-text>
            <v-range-slider
              v-model="range"
              :max="max"
              :min="min"
              class="align-center"
            ></v-range-slider>
            <v-row>
              <v-col class="px-4">
                <v-range-slider class="align-center">
                  <template v-slot:prepend>
                    <v-text-field
                      :value="range[0]"
                      class="mt-0 pt-0"
                      hint="From"
                      prepend-inner-icon="mdi-currency-usd"
                      type="number"
                      style="width: 60px"
                      @change="$set(range, 0, $event)"
                    ></v-text-field>
                  </template>
                  <template v-slot:append>
                    <v-text-field
                      :value="range[1]"
                      class="mt-0 pt-0"
                      prepend-inner-icon="mdi-currency-usd"
                      hint="To"
                      type="number"
                      style="width: 60px"
                      @change="$set(range, 1, $event)"
                    ></v-text-field>
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ul>
    </div>
    <!-- <div>
      <ul>
        <p>price</p>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Maccs</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Baars</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Bukks</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Luasis</label>
        </li>
        <li>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <label>Nike</label>
        </li>
      </ul>
    </div> -->
    <div>
      <ul>
        <p>Rating</p>
        <li>
          <div class="siderating">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <v-rating
              v-model="rating1"
              background-color="orange lighten-3"
              color="orange"
              small
            ></v-rating>
            <p>{{ rating1 }}</p>
          </div>
        </li>

        <li>
          <div class="siderating">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <v-rating
              v-model="rating2"
              background-color="orange lighten-3"
              color="orange"
              small
            ></v-rating>
            <p>{{ rating2 }}</p>
          </div>
        </li>
        <li>
          <div class="siderating">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <v-rating
              v-model="rating3"
              background-color="orange lighten-3"
              color="orange"
              small
            ></v-rating>
            <p>{{ rating3 }}</p>
          </div>
        </li>
        <li>
          <div class="siderating">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <v-rating
              v-model="rating4"
              background-color="orange lighten-3"
              color="orange"
              small
            ></v-rating>
            <p>{{ rating4 }}</p>
          </div>
        </li>
        <li>
          <div class="siderating">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <v-rating
              v-model="rating5"
              background-color="orange lighten-3"
              color="orange"
              small
            ></v-rating>
            <p>{{ rating5 }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      min: 0,
      max: 10000,
      range: [0, 7000],
      rating1: 5,
      rating2: 4,
      rating3: 3,
      rating4: 2,
      rating5: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.search_filter {
  padding: 30px;
  // margin-top: 20px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 20px 10px;
  width: 250px;
  // margin-left: 30px;
  // margin-top: 20px;
  border-radius: 7px;
  background-color: #ffffff;
  // position: fixed;
  height: 100%;
  overflow: auto;
}
.siderating {
  display: flex;
  flex-direction: row;
}
</style>
